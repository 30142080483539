import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import Layout from "../layouts/index";
import TooltipComponent from "../components/TooltipComponent";

const IndexPage = () => {
	const { t } = useTranslation();
	return (
		<Layout>
			<div className="homepage">
				{/* My Name Main Page */}
				<div className="myName">
					<div className="nameKana">
						<div className="nameJoseKana">{t("index-jose-jp")}</div>
						<div className="nameTomasKana">{t("index-tomas-jp")}</div>
						<div className="nameSierraKana">{t("index-sierra-jp")}</div>
					</div>
					<div className="nameAlphabet">
						<div className="nameJoseAlphabet">{t("index-jose-en")}</div>
						<div className="nameTomasAlphabet">{t("index-tomas-en")}</div>
						<div className="nameSierraAlphabet">{t("index-sierra-en")}</div>
					</div>
				</div>

				{/* Sub Header */}
				<div className="subheader">
					<div className="subheaderTitle">{t("index-subheader")}</div>
					<div className="subheaderContact">
						<span className="subheaderContactText">
							<a href="mailto:tomasjsierragmail.com">tomasjsierra@gmail.com</a>
						</span>
						<span className="subheaderContactIcon">
							<TooltipComponent />
						</span>
					</div>
				</div>

				{/* Description */}
				<div className="mainSection">
					{/* Background */}
					<div className="mainParagraph">
						{t("index-background-lawyer")}
						{t("index-background-comma")}
						{t("index-background-university")}
					</div>

					{/* LLM - Temple University */}
					<div className="mainParagraph">
						{t("index-temple-now")}
						{t("index-temple-llm")}
						{t("index-temple-school")}
					</div>

					{/* Languages */}
					<div className="mainParagraph">
						{t("index-languages-en")}
						{t("index-languages-comma")}
						{t("index-languages-jp")}
						{t("index-languages-comma")}
						{t("index-languages-es")}
					</div>
				</div>

				{/* Contact */}
				<div className="contactSection">
					<Link className="contactParagraph" to="/contact">
						{t("index-contact-description")}
					</Link>
				</div>
			</div>
		</Layout>
	);
};

export default IndexPage;

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
